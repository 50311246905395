import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchPerformances(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/performances', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchPerformance(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/performances/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updatePerformance(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/performances/${id}`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        generatePerformanceDocument(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/performances/${id}/generate`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}