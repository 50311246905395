import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { getPreviousMonthAndYear, getCurrentMonthAndYear } from '@core/utils/filter';

// Notification
import useNotifications from "@notifications"

export default function usePerformancesList() {

    // Use toast
	const { toastNotification } = useNotifications()
	const { month, year } = getCurrentMonthAndYear()

    const refPerformanceListTable = ref(null);

	const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    let tableColumns = [
		{ key: 'cycle_date', sortable: true, label: 'MES/AÑO',  ...styleColumns  },
		{ key: 'state_name', sortable: true, label: 'ESTADO', ...styleColumns  },
        { key: 'provider_name', sortable: true, label: 'PROVEEDOR', thClass: styleColumns.thClass },
        { key: 'security', sortable: true, label: 'SEGURIDAD ', ...styleColumns  },
        { key: 'productivity', sortable: true, label: 'PRODUCTIVIDAD', ...styleColumns  },
        { key: 'labor_risk', sortable: true, label: 'RIESGO LABORAL', ...styleColumns  },
        { key: 'calibration', sortable: true, label: 'TIENE <br/> CALIBRACIÓN', ...styleColumns  },
      /*   { key: 'labor_risk', sortable: true, label: '(IC) <br/> RIESGO LABORAL', ...styleColumns  },
        { key: 'colpa', sortable: true, label: '(IC) <br/> COLPA', ...styleColumns  },
        { key: 'financial_risk', sortable: true, label: '(IC) <br/> RIESGO FINANCIERO', ...styleColumns  },
        { key: 'rotation', sortable: true, label: '(II) <br/> ROTACIÓN', ...styleColumns  },
        { key: 'penalities', sortable: true, label: '(II) <br/> PENALIDADES', ...styleColumns  }, */		 
        { key: 'performance_name', sortable: true, label: 'NINE BOX', ...styleColumns  },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns  }
    ];

    const perPage = ref(10);
    const totalPerformances = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);

    const statesFilter = ref(null);
	const businessAreasFilter = ref(null);
	const monthFilter = ref(month);
	const yearFilter = ref(year);

    const dataMeta = computed(() => {
        const localItemsCount = refPerformanceListTable.value ? refPerformanceListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPerformances.value,
        }
    });

    const refetchData = () => {
        refPerformanceListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statesFilter, businessAreasFilter, monthFilter, yearFilter], () => {
        refetchData();
    });

    const fetchPerformances = (ctx, callback) => {
        store
            .dispatch('app-performance/fetchPerformances', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                states: statesFilter.value,
				businessAreas: businessAreasFilter.value,
				month: monthFilter.value,
				year: yearFilter.value,
            })
            .then(response => {
                const { performances, total } = response.data;
                callback(performances);
                totalPerformances.value = total;
            })
            .catch(() => {
				toastNotification('Error al obtener lista de desempeños', false)
            })
    };

    return {
        fetchPerformances,
        tableColumns,
        perPage,
        currentPage,
        totalPerformances,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPerformanceListTable,

        refetchData,

        // Extra Filters
		statesFilter,
		businessAreasFilter,
		monthFilter,
		yearFilter
    }
}