<template>
	<b-card no-body>
		<b-card-header>
			<h5 class="mb-0 text-white">
				<b>FILTROS</b>
			</h5>
		</b-card-header>
		<b-card-body class="pt-2">
			<b-row>
				<b-col
					cols="12"
					md="3"
					class="mb-2"
				>
					<label for="states">
						<strong>ESTADOS</strong>
					</label>
					<v-select
						id="states"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						multiple
						v-model="formData.statesArray"
						:options="options.states"
						placeholder="Seleccione"
						class="w-100"
						@input="changeStates"
					/>
				</b-col>

				<b-col
					cols="12"
					md="3"
					class="mb-2"
				>
					<label for="businessAreas">
						<strong>RUBRO(S)</strong>
					</label>
					<v-select
						id="businessAreas"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						placeholder="Seleccione"
						multiple
						v-model="formData.businessAreasArray"
						:options="options.businessAreas.filter(item => [1, 22].includes(item.value))"
						class="w-100"
						@input="changeBusinessAreas"
					/>
				</b-col>

				<b-col
					cols="12"
					md="3"
					class="mb-2"
				>
					<label for="month">
						<strong>MES</strong>
					</label>
					<v-select
						id="month"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						v-model="formData.month"
						:options="options.months"
						placeholder="Seleccione"
						class="w-100"
						@input="changeMonth"
					/>
				</b-col>

				<b-col
					cols="12"
					md="3"
					class="mb-2"
				>
					<label for="month">
						<strong>AÑO</strong>
					</label>
					<b-form-input
						id="month"
						class="w-100"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						v-model="formData.year"
						placeholder="Ej. 2024"
						@input="changeYear"
						type="number"
						trim
					/>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api"
import vSelect from "vue-select"
import {
	getPreviousMonthAndYear,
	getCurrentMonthAndYear
} from "@core/utils/filter"

export default {
	components: {
		vSelect
	},
	props: {
		options: {
			type: Object,
			required: true
		}
	},
	setup(props, { emit }) {
		const { month, year } = getCurrentMonthAndYear()

		// REFS
		const formData = ref({
			statesArray: [],
			businessAreasArray: [],
			month: null,
			year: null
		})

		onMounted(() => {
			if (props.options.months) {
				const selectedMonth = props.options.months.find(
					(item) => item.value === month
				)
				formData.value.month = selectedMonth || null
			}
			formData.value.year = year || null
		})

		const changeStates = () => {
			if (formData.value.statesArray) {
				const array = formData.value.statesArray.map((item) => item.value)
				emit("update:statesFilter", array.join(","))

				return array
			}

			return null
		}

		const changeBusinessAreas = () => {
			if (formData.value.businessAreasArray) {
				const array = formData.value.businessAreasArray.map(
					(item) => item.value
				)
				emit("update:businessAreasFilter", array.join(","))

				return array
			}

			return null
		}

		const changeMonth = () => {
			if (formData.value.month) {
				const month = formData.value.month.value
				emit("update:monthFilter", month)

				return month
			}

			return null
		}

		const changeYear = (value) => {
			if (value === "" || Number(value) > 0) {
				formData.value.year = value
			} else {
				formData.value.year = ""
			}

			if (formData.value.year) {
				const year = formData.value.year
				emit("update:yearFilter", year)
			}
		}

		return {
			// DATA
			formData,

			// METHODS
			changeStates,
			changeBusinessAreas,
			changeMonth,
			changeYear
		}
	}
}
</script>

<style lang="scss" scoped>
.card-header {
	background-color: var(--primary);
	padding: 1rem 1.5rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>